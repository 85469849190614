.ant-layout {
  /* f0f2f5 */
  background: #e1e9f7 !important;
}

.ant-layout-header {
  padding: 0 !important;
}

.counter-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 3fr 1fr 1fr;
  height: 70vh;
}

.counter-total {
  border-radius: 9px;
  background-color: #8fbc8f;
  color: #fff;
  font-weight: 700;
  font-size: 6em;
  text-align: center;
}

.counter-btn {
  margin-top: 30px;
  border-radius: 9px;
  font-size: 5em;
  color: white;
  text-align: center;
  border: none;
}

.counter-plus-btn {
  background-color: #1e7e34;
  border-color: #1c7430;
  cursor: pointer;
}
.counter-plus-btn:hover {
  background-color: #179133;
}

.counter-minus-btn {
  background-color: #dc3545;
  cursor: pointer;
}
.counter-minus-btn:hover {
  background-color: #dd182b;
}

.pin-field-container {
  display: grid;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  justify-content: center;
  margin: 4rem 0;
}

.pin-field {
  background-color: rgb(248, 249, 250);
  border: 1px solid rgb(204, 204, 204);
  border-radius: 0.3rem;
  font-size: 2rem;
  margin: 0.25rem;
  height: 3.5rem;
  outline: none;
  text-align: center;
  transition-duration: 250ms;
  transition-property: background, color, border, box-shadow, transform;
  width: 3rem;
}

.pin-field:focus {
  border-color: rgb(0, 123, 255);
  outline: none;
  transform: scale(1.05);
}

.pin-field:invalid {
  animation: shake 3 linear 75ms;
  border-color: rgb(220, 53, 69);
  box-shadow: 0 0 0.25rem rgba(220, 53, 69, 0.5);
}
.pin-field:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

@keyframes shake {
  from {
    transform: scale(1.05) translateY(-5%);
  }
  to {
    transform: scale(1.05) translateY(5%);
  }
}
